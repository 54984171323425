<template>
    <div v-loading="loading">
        <div class="wz">
            <div style="padding-top:4px;">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>招聘会</el-breadcrumb-item>
                    <el-breadcrumb-item>招聘会详情</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <el-button @click="$router.back(-1)" icon="el-icon-back" size="small" style="float:right; margin-top:0px;">返回</el-button>
        </div>
        <div class="jobtop">
            <div class="jobtitle"><i></i><em>招聘会信息</em></div>
            <div class="jobtopcont">
                <div class="jobtoppic"><img :src="detail.jobFairPojo.url" alt=""></div>
                <div class="jobtopbox">
                    <span class="jobh1">{{detail.jobFairPojo.jobName}}</span>
                    <span>主 办 方：{{detail.jobFairPojo.organizer}}</span>
                    <span>举办时间：{{detail.jobFairPojo.holdStartTime}}至{{detail.jobFairPojo.holdEndTime}}</span>
                    <span>举办会场：{{detail.jobFairPojo.holdField}}</span>
                    <span>公交：{{detail.jobFairPojo.trafficRoutes}}</span>
                    <div class="tel" style="width:600px;">
                        <i>联系<br />电话</i>
                        <strong>{{detail.jobFairPojo.contactsPhone}}</strong>
                        <em>( {{detail.jobFairPojo.contacts}} )</em>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="jobimg">
             <div class="jobtitle"><i></i><em>招聘会会场图</em></div>
             <img :src="detail.boothPojo.floorPlan" alt="">
        </div>
        <div class="wzbox" v-if="ishave">
            <div class="jobtitle"><i ></i><em>招聘会展位</em></div>
            <div class="wzabout">
                <span class="color4"><i class="el-icon-user-solid"></i>不可预定</span>
                <span class="color1"><i class="el-icon-user-solid"></i>已被预定</span>
                <span class="color2"><i class="el-icon-user-solid"></i>可以预定</span>
                <span class="color3"><i class="el-icon-user-solid"></i>审核中</span>
            </div>
            <template v-for="(item,index) in detail.boothRegionFroms">
                <div class="regintitle">{{item.boothRegion}}</div>
                <div class="position2">
                    <ul>
                        <li v-for="(wzitem,wzindex) in item.boothSeatPojos" :key="wzindex" :class="'posi'+wzitem.status">
                            <i class="el-icon-user-solid " :class="'color'+wzitem.status"></i>
                            <em v-if="wzitem.status==2">可预定</em>
                            <em v-if="wzitem.status==4">不可预定</em>
                            <em v-if="wzitem.status==1">已被预定</em>
                            <em v-if="wzitem.status==3">审核中</em>
                            <p>{{wzitem.seatNum>9?wzitem.seatNum:'0'+wzitem.seatNum}}</p>
                            <div class="company"><div class="companyname">{{wzitem.firm}}</div> <template ><u class="el-icon-close" @click="examine(wzitem,2)"></u> <u v-if="wzitem.status==3" class="el-icon-check" @click="examine(wzitem,1)"></u></template></div>
                            <div class="posicz">
                                <span v-if="wzitem.status==2" style="background:#21d0b6; margin:0px 6px;" @click="locking(wzitem,item,index)">锁定</span>
                                <span v-if="wzitem.status==4" style="background:#21d0b6; margin:0px 6px;" @click="lockingout(wzitem,item,index)">解除锁定</span>
                                <span v-if="wzitem.status==4" style="background:#21d0b6;" @click="opensetcompany(wzitem,item,index)">设定企业</span>
                            </div>
                        </li>
                       
                    </ul>
                </div>
            </template>
        </div>
        <div class="wzbox">
            <div class="jobtitle"><i ></i><em>预约企业</em></div>
            <div class="companylist">
               <ul>
                   <li v-for="comitem in detail.companyAndLocation">
                       <span>{{comitem.firm}}</span>
                       <em>（{{comitem.regionName}}-{{comitem.seatNum}}）</em>
                       <i>
                           <el-popover
                                placement="top"
                                title="预约职位"
                                width="300"
                                trigger="hover">
                                <slot name="content">
                                    <div class="joblist">
                                        <div class="jobitem" v-for="zwitem in comitem.positionNameAndNumber">
                                            <div class="jobitemleft">{{zwitem.split(":")[0]}}</div>
                                            <div class="jobitemright">{{zwitem.split(":")[1]}}人</div>
                                        </div>
                                    </div>
                                </slot>
                                <u slot="reference">查看职位</u>
                            </el-popover>
                       </i>
                   </li>
                  
               </ul>
            </div>
        </div>
        <div class="wzbox">
            <div class="jobtitle"><i ></i><em>招聘会介绍</em></div>
            <div class="showabout">
                <pre>{{detail.jobFairPojo.jobFairIntroduce}}</pre>
            </div>
        </div>
        <div class="wzbox">
            <div class="jobtitle"><i ></i><em>媒体宣传</em></div>
            <div  class="showabout">
                <pre>{{detail.jobFairPojo.mediaPublicity}}</pre>
            </div>
        </div>
        <div class="wzbox">
            <div class="jobtitle"><i ></i><em>超值服务套餐</em></div>
            <div class="showabout">
                <pre>{{detail.jobFairPojo.jobExplain}}</pre>
            </div>
        </div>
        <div class="wzbox">
            <div class="jobtitle"><i ></i><em>展位设置方案</em></div>
            <div class="showabout">
                <pre>{{detail.jobFairPojo.boothSettingScheme}}</pre>
            </div>
        </div>
        <div class="wzbox">
            <div class="jobtitle"><i ></i><em>参与办法</em></div>
            <div class="showabout">
                <pre>{{detail.jobFairPojo.participatoryApproach}}</pre>
            </div>
        </div>
        
        <pop-up :title="'设定企业'" width="30%" :isshow="setcompany" :haveconfirm="false" @close="getclose" @confirm="getconfirm">
            <div slot="box">
                <div style="text-align:center;"><el-input v-model="keyword" size="small" style="width:280px;margin-right:8px;" placeholder="输入企业名称搜索"></el-input><el-button size="small" type="primary" class="mybotton" @click="searchcompany">搜索</el-button></div>
                <div class="companylist">
                    <div class="companyitem" v-for="item in companylist"><span>{{item.userName}}</span><el-tag type="primary" size="mini" style="float:right; margin-top:5px; cursor: pointer;" @click="getZhiwei(item.id)">选择职位</el-tag></div>
                </div>
            </div>
        </pop-up>

        <pop-up :title="'选择职位'" width="27%" :isshow="checkzhiwei" :haveconfirm="true" @close="getclose9" @confirm="getconfirm8">
            <div slot="box">
                <el-checkbox-group v-model="checkList">
                    <el-checkbox v-for="item in zhiwei"  :label="item.id">{{item.workName}}</el-checkbox>
                </el-checkbox-group>
            </div>
        </pop-up>

    </div>
</template>
<script>
import apiurl from '@/api/booth'
import _api from '@/api/index'
export default {
    data(){
        return{
            id:"",
            keyword:"",
            companyid:"",
            companylist:[],
            loading:false,
            checkList:[],
            zhiwei:[],
            setcompany:false,
            checkzhiwei:false,
            detail:{
                jobFairPojo:{},
                boothRegionFroms:[],
                boothPojo:{}
            },
            lockitem1:{},
            lockitem2:{},
            ishave:true,//是否有选择位置方案
        }
    },
    created(){
        this.id=this.$route.query.id
        this.getDetail()
        
    },
    methods:{
        getZhiwei(id){//根据公司id获取职位
            this.companyid=id
            _api.get(apiurl.selectWorkByMemberIdNotPage,{memberId:id}).then(res=>{
                console.log(res)
                if(res.success){
                    this.zhiwei=res.data
                    this.checkzhiwei=true
                }
            })
        },
        searchcompany(){
            _api.get(apiurl.queryMemberByCompanyName,{company:this.keyword}).then(res=>{//获取企业
                console.log(res)
                if(res.success){
                    this.keyword=""
                    this.companylist=res.data
                }
            })
        },
        lockingout(posiitem,qyitem,index){//解除位置锁定
            _api.get(apiurl.location,{id:posiitem.id,regionId:qyitem.id,jobId:this.id,status:2}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success(res.message)
                    this.getDetail()
                }else{
                    this.$message.warning(res.message)
                }
            })
        },
        locking(posiitem,qyitem,index){//位置锁定
            _api.get(apiurl.location,{id:posiitem.id,regionId:qyitem.id,jobId:this.id,status:4}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success(res.message)
                    this.getDetail()
                }else{
                    this.$message.warning(res.message)
                }
            })
        },
        examine(row,status){
            console.log(row)

            _api.get(apiurl.locationReview,{seatId:row.id,jobId:this.id,status:status,memberId:row.firmId}).then(res=>{
                console.log(res)
                
                if(res.success){
                    this.$message.success("操作成功！")
                    this.getDetail()
                }
            })
        },
        getDetail(){
            this.loading=true
            _api.get(apiurl.detailInfo,{id:this.id}).then(res=>{
                this.loading=false
                console.log(res)
                if(res.success){
                    this.detail=res.data
                    console.log(56)
                    console.log(this.detail.boothPojo)
                    var len=Object.keys(res.data.boothPojo).length
                    console.log(len)
                    if(len==0){
                        this.ishave=false
                    }else{
                        for(let item of this.detail.companyAndLocation){
                            item.positionNameAndNumber=item.positionNameAndNumber.split(",");
                        }
                        console.log(this.detail)
                    }
                }
            })
        },
        getconfirm8(){
            if(this.checkList.length>0){
            var positionIdstr=this.checkList.join(",")
             _api.get(apiurl.location,{id:this.lockitem1.id,regionId:this.lockitem2.id,jobId:this.id,status:2,memberId:this.companyid,positionId:positionIdstr}).then(res=>{
                console.log(res)
                console.log(888)
                if(res.success){
                    this.$message.success(res.message)
                    this.getDetail()
                    this.checkzhiwei=false
                    this.setcompany=false
                    this.checkList=[]
                }else{
                    this.$message.error(res.message)
                }
            }).catch(err=>{
                this.$message.error(err.data.message)
            })
            }else{
                this.$message.error("请选择预约的职位！")
            }
        },
        opensetcompany(a,c,b){
            console.log(a)
            if(a.status!=4){
                this.$message.error("请先锁定位置")
                return
            }
            this.lockitem1=a
            this.lockitem2=b
            this.setcompany=true
        },
        getconfirm(data){
            this.setcompany=false
        },
        getclose(){
            this.setcompany=false
        },
        getclose9(){
            this.checkzhiwei=false
        },
        
    }
}
</script>
<style scoped>
@import "../../assets/css/jobfair.css";
.companyname{ float: left; width: 175px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
</style>